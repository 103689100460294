section.Home{
    height: 100vh;
    div.coming-soon{
        background-image: url('../../assets/images/deep\ ship.png'); /* The image used */
        height: 100%;
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
        background-attachment: fixed;
        div{
            height: 100%;
            color:#a3c2fc;
            font-size: 8vw;
            span.coming-soon-txt{
                font-size: 10vw;
                font-weight: bolder;
            }
            span.timer-txt{
                font-size: 5vw;
            }
            span.timer-num{
                font-weight: bolder;
            }
        }
    }
}
section.NotFound{
    height: 100vh;
    div.not-found {
        background-image: url('../../assets/images/page\ not\ found.png'); /* The image used */
        height: 100%;
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
        background-attachment: fixed;
        div{
            height: 100%;
            span.not-found-txt{
                font-size: 8vw;
                font-weight: bolder;
                color:#dddae7;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    section.NotFound div.not-found div{
        justify-content: center !important;
        span.not-found-txt{
            font-size: 12vw;
        }
    }
}